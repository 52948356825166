// Fix error display on safari mobile
button.delete {
  padding: 0 !important;
}

.button {
  &.is-primary {
    color: var(--primary-invert);
    background-color: var(--primary);

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      color: var(--primary-invert);
      background-color: var(--primary);
    }
  }

  &.is-link {
    color: var(--secondary-invert);
    background-color: var(--secondary);

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      color: var(--secondary-invert);
      background-color: var(--secondary);
    }
  }

  &.is-fullwidth-mobile {
    @media screen and (max-width: 768px) {
      display: flex;
      width: 100%;
    }
  }

  &--cta {
    height: auto;
    padding: 15px;
    line-height: 23px;
  }

  &.is-large {
    font-size: 1.5rem;
  }

  &.has-text-link.is-loading {
    &:hover,
    &:focus {
      color: transparent !important;
    }
  }
}
